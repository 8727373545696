.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.title-page{
  background: url(background.jpg) center;
  max-width: 100%;
  height: 100vh;
}

.title-content{
  padding-top: 15em;
  padding-bottom: 20em;
  padding-left: 10em;
  padding-right: 10em;

}
.title-content h1{
  vertical-align: middle;
}

h1{
  padding-bottom: 0.5em;
  font-size: 5em;
  font-weight: bold;

}

.section-title{
  margin-top: 1em;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.navbar{
  font-size: 2em;
  /* background-color: white; */
}

.navbar-nav{
  background-color: white;
  margin-top: 1.5em;
  border-radius: 0.5em;
  color:#08629a;
  border-style: solid;
  border-width: 0.1em;
  border-color: #08629a;
}

.navbar-item{
  margin-left: 1em;
  margin-right: 1em;
}

a.nav-link{
  color:#08629a;
}

.section-title{
  color:#08629a;
}

#portfolio-page{
  background-color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/** Safari */
@media not all and (min-resolution: 0.001dpcm) {
  .title-content{
    padding-top: 20em;
    padding-bottom: 20em;
    padding-left: 15em;
    padding-right: 15em;
  }
}

